@import '/node_modules/bootstrap/scss/bootstrap.scss';

body{
    background-image: url('./background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fadein {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1;}
}

.main{
    width: 80vw;
    margin: auto;
    padding: 25px;
    max-width: 1000px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    --webkit-backdrop-filter: blur(5px);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0 , 0.5);
    border-radius: 10px;
    transform: scale(1);
    transition: transform 0.3s linear;
}

.main > h1,
.main > h2 {
    text-align: center;
    font-family: 'Rock Salt', cursive;
}

.logotypes {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    margin-top: 25px;
}

.WFAiIS {
    width: 315px;
}


.main > form >  div {
    margin: 25px !important;
}

.main > form > button[type="submit"]{
   width: 100%;
}

.counter{
    font-size: 0.8em;
    text-align: right;
}

#info {
    height: 100px;
    resize: none;
}

.finalization {
    margin: 25px;
    text-align: center;
}

@media screen and (max-width: 480px){
    .main {
        width: 100%;
        margin: 0;
        font-size: 0.9em;
    }
    
    .main > form >  div {
        margin: 15px 5px 15px 5px !important;
    }

    .main > h1 {
        margin-bottom: 25px;
    }

    
}